/**
 * Set cookie
 *
 * @param string name
 * @param string value
 * @param int days
 * @param string path
 * @see http://www.quirksmode.org/js/cookies.html
 */
function createCookie(name, value, days, path) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=" + path;
}

/**
 * Read cookie
 * @param string name
 * @returns {*}
 * @see http://www.quirksmode.org/js/cookies.html
 */
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

/**
 * Set consent cookie
 */
function acceptConsent(status) {
    var cookieExpiry = cookieMessage.getAttribute('data-cookie-expiry');
    if (cookieExpiry == null) {
        cookieExpiry = 30;
    }
    if (status == false) {
        cookieExpiry = 7;
    }
    var cookiePath = cookieMessage.getAttribute('data-cookie-path');
    if (cookiePath == null) {
        cookiePath = "/";
    }

    createCookie('consent-approved', status, cookieExpiry, cookiePath);
    cookieMessage.style.display = 'none';
}

window.optOut=function(){
    createCookie('consent-approved', "", -1);
    this.console.log('cookie deleted');
}

// Consent message
var cookieMessage = document.getElementById('cookie-message');
var cookie = readCookie('consent-approved');
if (cookie != null && (cookie == 'true' || cookie == 'false')) {
    cookieMessage.style.display = 'none';
} else {
    cookieMessage.style.display = 'block';
}


/*===========================
 *     01.  Loader          *
 *     02.  Menu            *
 *     03.  Sticky Menu     *
 *     03.  Back to top     *
 ===========================*/
! function ($) {
    "use strict";
    // Loader 
    $(window).on('load', function () {
        $('#status').fadeOut();
        $('#preloader').delay(350).fadeOut('slow');
        $('body').delay(350).css({
            'overflow': 'visible'
        });
    });

    // // Menu
    // $('.navbar-toggle').on('click', function (event) {
    //     console.log('cliked')
    //     $(this).toggleClass('open');
    //     $('#navigation').slideToggle(400);
    // });

    // $('.navigation-menu>li').slice(-1).addClass('last-elements');

    // $('.menu-arrow,.submenu-arrow').on('click', function (e) {
    //     if ($(window).width() < 992) {
    //         e.preventDefault();
    //         $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
    //     }
    // });

    // $(".navigation-menu a").each(function () {
    //     if (this.href == window.location.href) {
    //         $(this).parent().addClass("active");
    //         $(this).parent().parent().parent().addClass("active");
    //         $(this).parent().parent().parent().parent().parent().addClass("active");
    //     }
    // });

    // // Clickable Menu
    // $(".has-submenu a").click(function () {
    //     if (window.innerWidth < 992) {
    //         if ($(this).parent().hasClass('open')) {
    //             $(this).siblings('.submenu').removeClass('open');
    //             $(this).parent().removeClass('open');
    //         } else {
    //             $(this).siblings('.submenu').addClass('open');
    //             $(this).parent().addClass('open');
    //         }
    //     }
    // });

    // $('.mouse-down').on('click', function (event) {
    //     var $anchor = $(this);
    //     $('html, body').stop().animate({
    //         scrollTop: $($anchor.attr('href')).offset().top - 0
    //     }, 1500, 'easeInOutExpo');
    //     event.preventDefault();
    // });

    //Sticky
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
            $(".sticky").addClass("nav-sticky");
        } else {
            $(".sticky").removeClass("nav-sticky");
        }
    });

    // Back to top
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    });
    // $('.back-to-top').click(function () {
    //     $("html, body").animate({ scrollTop: 0 }, 3000);
    //     return false;
    // });
}(jQuery)
